<script>
export default {

    components: { },

    data() {
        return {
            title: '',
        }
    },

    watch: {

    },

    computed: {
    },

    async mounted() {
        
    },

    methods: {

    }
}
</script>
<style>
    body {
        padding-top: 0px !important;
    }
</style>
<template>
    <div class="app-content content" style="margin: 0px !important; height: 100vh; width: 100vw; background: url(/assets/images/backgrounds/bg-2.jpg) center center no-repeat fixed;
    -webkit-background-size: cover; background-size: cover;">
        <div class="content-wrapper">
          <div class="content-header row">
          </div>
          <div class="content-body">
  
              <section id="account-login" class="flexbox-container">    
                
      <div class="col-12 d-flex align-items-center justify-content-center">
          <!-- image -->
          <div class="col-xl-3 col-lg-4 col-md-5 col-sm-5 col-12 p-0 text-center d-none d-md-block">
              <div class="border-grey border-lighten-3 m-0 box-shadow-0 card-account-left height-400">
                  <img src="/assets/images/logo/logo.svg" class="card-account-img" width="100" alt="card-account-img">
              </div>
          </div>
  
          <!-- login form -->
          <div class="col-xl-3 col-lg-4 col-md-5 col-sm-5 col-12 p-0">
              <div class="card border-grey border-lighten-3 m-0 box-shadow-0 card-account-right height-400">                
                  <div class="card-content">                    
                      <div class="card-body p-3">
                          <p class="text-center h5 text-capitalize">Добро пожаловать!</p>
                          <p class="mb-3 text-center">Пожалуйста, укажите ваши регистрационные данные</p>
                          <form class="form-horizontal form-signin" action="/dashboard">                            
                              
                            <fieldset class="form-label-group">
                                  <input type="text" class="form-control" id="user-name" placeholder="Укажите e-mail" value="" required="" autofocus="">
                                  <label for="user-name">E-mail</label>
                              </fieldset>
                              <fieldset class="form-label-group">
                                  <input type="password" class="form-control" id="user-password" placeholder="Укажите пароль" value="" required="" autofocus="">
                                  <label for="user-password">Пароль</label>
                              </fieldset>

                              <div class="form-group row">
                              </div>
                              <button type="submit" class="btn-gradient-primary btn-block my-1">Войти</button>
                              <p class="text-center"><a href="/register" class="card-link">Регистрация</a></p>
                          </form>
                      </div>                    
                  </div>
              </div>
          </div>        
      </div>    
  </section>
  
          </div>
        </div>
      </div>
  
</template>